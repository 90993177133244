import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';

import axios from 'axios';
import FileDownload from 'js-file-download';
import toast, { Toaster } from 'react-hot-toast'

function App() {

	const [url, setURL] = useState("")
	const [loading, setLoading] = useState(false);

	 const toastId = React.useRef(null);
	  const notify = (message) => (toastId.current = toast.loading(message));
	  const update = (message) => toast.loading(message, { id: toastId.current });
	  const success = (message) => toast.success(message, { id: toastId.current });
	  const error = (e) => {
	    toast.error(e, { id: toastId.current });
	  };

	const fetchAudioFromServer = () => {
		if (url) {
			let vid = null
			try {
				vid = url.match(/\?v=(.*)$/)[1];
				notify("Je convertis : " + vid)
			}
			catch (e) {
				error("Le lien n'est pas au bon format :/");
			}

			if (vid) {
				setLoading(true);
				axios.request({
					method: "GET", 
					url: `https://ytmp3.vct.xyz/convert/${vid}`, 
					responseType: 'blob',
					onUploadProgress: (p) => {
						console.log(p);
						setLoading(true);
					}
				}).then(response => {
					setLoading(false);

					console.log(response)

	        console.log(response.headers)
	        const headerval = response.headers['content-disposition'];
        	let filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        	console.log(filename)

					success("Yay !")
					FileDownload(response.data, filename);
				}).catch(response => {
					setLoading(false)
					error("Oh oh, ça n'a pas marché :/")
				})
			}
		}
		else {
			error("Pas d'url à convertir !")
		}
	}

	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					Youtube Maxx downloader
				</p>
				<main className="p-4">
					<input
						className="my-8 px-8 bg-gray-600 appearance-none rounded w-full py-2 px-4 text-white text-2xl leading-tight focus:outline-none"
						id="url"
						type="text"
						placeholder="https://www.youtube.com/watch?v=XD2JBh6Iui8"
						onChange={(e) => setURL(e.target.value)}
					/>
					<button
						className="w-full flex items-center justify-center py-3 text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:text-lg"
						onClick={() => fetchAudioFromServer()}
					>
						{loading
							? <span className="flex">
									<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
						        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
						      </svg>
						      Ça charge !
								</span>
							: <span>Télécharger le son</span>
						}
					</button>
				</main>
			</header>
			<Toaster />
		</div>
	);
}

export default App;
